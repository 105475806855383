import React from 'react';
import {Typography, Button, Divider} from '@material-ui/core';
import {Elements, CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Review from './Review';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = ({checkoutToken, nextStep, backStep, shippingData, onCaptureCheckout}) => {
    const handleSubmit = async (event, elements, stripe) => {
        event.preventDefault();
        
        if(!stripe || !elements){
            if(!stripe) console.log("No stripe!");
            if(!elements) console.log("No elements!")
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({type: 'card', card: cardElement});

        if(error){
            console.log(error);
        }
        else{
            console.log("Here is shippingData: ", shippingData);

            const orderData = {
                line_items: checkoutToken.live.line_items,
                customer: { firstname: shippingData.firstName, lastname: shippingData.lastName, email: shippingData.email },

                shipping: { 
                            name: shippingData.firstName + " " + shippingData.lastName,
                            street: shippingData.address1 + " "  + shippingData.address2,
                            town_city: shippingData.city, 
                            county_state: shippingData.shippingSubdivision,
                            postal_zip_code: shippingData.zip,
                            country: shippingData.shippingCountry 
                        },

                fulfillment: { shipping_method: shippingData.shippingOption },
                payment: {
                  gateway: 'stripe',
                  stripe: {
                    payment_method_id: paymentMethod.id,
                  },
                },
              };
            console.log("Here is orderData: ", orderData);
            onCaptureCheckout(checkoutToken.id, orderData);
            nextStep();
        }
    }
    return (
        <>
            <Review checkoutToken={checkoutToken} />
            <Divider />
            <Typography variant="h6" gutterBottom style={{margin: '20px 0'}}>¡ Payment method !</Typography>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({elements, stripe}) =>(
                        <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
                            <CardElement />
                            <br /> <br />
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button variant="outlined" onClick={backStep}>Back</Button>
                                <Button type="submit" variant="contained" disabled={!stripe} color="primary">
                                    Pay ${checkoutToken.live.subtotal.raw+5}.00
                                </Button>
                            </div>
                        </form>
                    )}
                </ElementsConsumer>
            </Elements>
        </>
    )
}

export default PaymentForm
